<template>
  <div class="dad">
    <left-img :target="'/login'"></left-img>
    <div class="select_type">
      <div class="title">
        <p style="font-size:30px;">注册</p>
        <p>请选择注册身份</p>
      </div>
      <div class="jf checked" @click="jump_regist(1)">
        <img src="../../public/images/jf.png" alt />
        <span>警方端注册</span>
        <span>警方人员注册入口</span>
      </div>
      <div class="jf checked" @click="jump_regist(0)">
        <img src="../../public/images/qy.png" alt />
        <span>企业端注册</span>
        <span>企业人员注册入口</span>
      </div>
      <div class="jf" @click="jump_regist(2)">
        <img src="../../public/images/qy.png" alt />
        <span>分析公司注册</span>
        <span>分析公司注册入口</span>
      </div>
    </div>
  </div>
</template>
<script>
import leftImg from './left_img.vue'
export default {
  data() {
    return {}
  },
  components: {
    leftImg
  },
  methods: {
    jump_regist(type) {
      this.$router.push('/regist?type=' + type)
    }
  }
}
</script>
<style scoped>
.dad {
  height: 100%;
  background: url('../../public/images/bssjx.png') no-repeat center;
}
.select_type {
  width: 1000px;
  height: 380px;
  position: absolute;
  top: 15%;
  left: 40%;
  transform: translate(-10%);
  padding: 50px 20px;
  background-color: #fff;
}
.title p {
  text-align: center;
  width: 100%;
}
.title p:nth-of-type(2) {
  color: #999;
}
.jf {
  height: 100%;
  width: 29%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  float: left;
  border: 2px solid #ccc;
  border-radius: 6px;
}
.jf:hover {
  border: 4px solid rgb(40, 56, 82);
}
.checked {
  margin-right: 50px;
}
.checked:hover {
  margin-right: 46px;
}
.jf span:nth-of-type(2) {
  color: #999;
  font-size: 14px;
}
.jf > span {
  margin-top: 20px;
  font-size: 18px;
}
@media screen and (max-width: 1660px) {
  .select_type {
    left: 20%;
  }
}
@media screen and (max-width: 550px) {
  .select_type {
    width: 90%;
    height: 57%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 50px 20px;
    border-radius: 10px;
    background-color: #fff;
  }
  .jf {
    height: 60%;
    width: 45%;
  }
  .checked {
    margin-right: 6.5%;
  }
  .jf:hover {
    border: 2px solid;
  }
}
</style>